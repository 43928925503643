import React, { createContext, useContext, useReducer } from 'react';
import { useCallbackRef } from '@chakra-ui/react';

import {
  AnalyticsClient,
  AnalyticsContext,
  useAnalytics,
} from '@arena-labs/analytics';
import { PlayableMedia } from '@arena-labs/shared-models';

import { MediaQueue, MediaQueueProps } from '../media-queue';

type MediaOptions = {
  onClose?: () => unknown;
  mode?: 'loose' | 'strict';
  showEndPrompt?: MediaQueueProps['showEndPrompt'];
  endPrompt?: MediaQueueProps['endPrompt'];
};

type MediaPlayerState =
  | Record<string, never>
  | {
      media: PlayableMedia;
      options?: MediaOptions | null;
      analytics?: AnalyticsClient | null;
    };

type MediaPlayerActions =
  | {
      type: 'Open';
      media: PlayableMedia;
      options?: MediaOptions;
      analytics?: AnalyticsClient;
    }
  | {
      type: 'Close';
    };

const MediaPlayerContext = createContext<{
  state: MediaPlayerState;
  dispatch: React.Dispatch<MediaPlayerActions>;
}>({ state: {}, dispatch: () => null });

function reducer(
  state: MediaPlayerState,
  action: MediaPlayerActions,
): MediaPlayerState {
  switch (action.type) {
    case 'Open': {
      return {
        media: action.media,
        options: action.options,
        analytics: action.analytics,
      };
    }
    case 'Close': {
      return {};
    }
  }
  return state;
}

export type UseMediaPlayerOptions = {
  analytics?: AnalyticsClient;
};

export function useMediaPlayer({ analytics }: UseMediaPlayerOptions = {}) {
  const fallbackAnalytics = useAnalytics();
  const { state, dispatch } = useContext(MediaPlayerContext);
  const openPlayer = useCallbackRef(
    (media: PlayableMedia, options: MediaOptions = {}) =>
      dispatch({
        type: 'Open',
        media,
        options,
        analytics: analytics ?? fallbackAnalytics,
      }),
  );

  const closePlayer = useCallbackRef(() => dispatch({ type: 'Close' }));

  return {
    ...state,
    openPlayer,
    closePlayer,
  };
}

export type MediaPlayerProps = {
  children: React.ReactNode;
  onPlayerClosed?: () => void;
};

export function MediaPlayer({ children, ...props }: MediaPlayerProps) {
  const [state, dispatch] = useReducer(reducer, {});
  const { onClose, ...options } = state.options || {};

  const onPlayerClosed = useCallbackRef((progress?: number) => {
    dispatch({ type: 'Close' });
    props.onPlayerClosed?.();
    onClose?.();
  });

  return (
    <MediaPlayerContext.Provider value={{ state, dispatch }}>
      <AnalyticsContext parent={state.analytics} context={{}}>
        {children}
        {state.media && (
          <MediaQueue.Modal
            placement="bottom"
            isOpen={Boolean(state.media)}
            items={[state.media]}
            onClose={onPlayerClosed}
            {...options}
          />
        )}
      </AnalyticsContext>
    </MediaPlayerContext.Provider>
  );
}
