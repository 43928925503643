import { forwardRef } from 'react';
import { BoxProps } from '@chakra-ui/react';

import { PlayableMedia, PlayableVideo } from '@arena-labs/shared-models';
import { durationToMs, formatCoachName } from '@arena-labs/strive2-ui';

import {
  useInitialMediaStartTime,
  useMediaProgressHandler,
} from '../media/media-progress-store';
import { getVideoUrls } from '../media/media-url';
import { MediaHandleRef } from '../media-handle';
import { useMutedPreference } from '../use-muted-preference';
import { useSubtitlesPreference } from '../use-subtitles-preference';
import { VerticalVideo, VerticalVideoProps } from '../vertical-video';
import { EndPromptDisplay, EndPromptDisplayProps } from './end-prompt-display';

type MediaQueueVideoProps = {
  mode: 'strict' | 'loose';
  video: PlayableVideo;
  videoProps?: Partial<VerticalVideoProps>;
  thumbnailWidth?: number;
  isFullScreen?: boolean;
  isActive?: boolean;
  autoPlay?: boolean;
  onEnded?: () => void;
  onClose?: () => void;
  boxProps?: BoxProps;
  beforeContinue?: () => Promise<unknown>;
  nextMedia?: PlayableMedia;
  showEndPrompt?: boolean;
  endPrompt?: EndPromptDisplayProps['render'];
};

export const MediaQueueVideo = forwardRef<MediaHandleRef, MediaQueueVideoProps>(
  function MediaQueueVideo({ video, ...props }, ref) {
    const [subtitles, setSubtitles] = useSubtitlesPreference();
    const [mutePref, setMutePref] = useMutedPreference();

    const [startTime, setStartTime] = useInitialMediaStartTime(video, {
      staleTime: durationToMs({ weeks: 1 }),
    });
    const rememberProgress = useMediaProgressHandler(video);

    const { videoUrl, imageUrl } = getVideoUrls(video, {
      width: props.thumbnailWidth,
      startTime,
    });

    return (
      <VerticalVideo
        {...props.videoProps}
        videoUrl={videoUrl}
        thumbnailUrl={imageUrl}
        title={video.title}
        muxData={{
          video_title: video.title,
          video_stream_type: 'on-demand',
          video_id: video.slug,
        }}
        presenterName={video.coach ? formatCoachName(video.coach) : null}
        watched={video.watched}
        description={video.description}
        withSpeedControl
        isFullScreen={props.isFullScreen}
        objectFit={'contain'}
        autoPlay={props.autoPlay}
        onLoadedMetadata={setStartTime}
        onTimeUpdate={rememberProgress}
        subtitlesLanguage={subtitles}
        onSubtitleChange={(track) => setSubtitles(track?.language || false)}
        muted={mutePref}
        onMutedChange={(mutedPref) => {
          if (mutedPref !== mutePref) {
            setMutePref(mutedPref);
          }
        }}
        ref={ref}
        onClose={props.onClose}
        showCloseButton={props.mode === 'loose'}
        onEnded={props.onEnded}
        boxProps={props.boxProps}
      >
        {(player) =>
          props.showEndPrompt && (
            <EndPromptDisplay
              media={video}
              nextMedia={props.nextMedia}
              isActive={props.isActive}
              player={player}
              beforeContinue={props.beforeContinue}
              onClose={props.onClose}
              position="relative"
              render={props.endPrompt}
            />
          )
        }
      </VerticalVideo>
    );
  },
);
