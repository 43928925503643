import 'videojs-mobile-ui';
import 'videojs-mux';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Capacitor } from '@capacitor/core';
import {
  Badge,
  Box,
  BoxProps,
  chakra,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SystemStyleObject,
  Text,
  useCallbackRef,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'clsx';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

import {
  CloseIcon,
  Icon,
  InfoIcon,
  VideoPlayerComponent,
} from '@arena-labs/strive2-ui';

import { useMediaPlaybackMachine } from './media/media-playback.machine';
import { MediaScrubber } from './media/media-scrubber';
import {
  useMediaIsSuspended,
  useSuspendableMedia,
} from './media/media-suspender';
import { PlaybackRateButtons } from './media/playback-rate-buttons';
import { MediaHandleRef } from './media-handle';
import { MuxVideoData } from './mux-data';

declare module 'video.js' {
  interface VideoJsPlayer {
    textTrackSettings: videojs.TextTrackSettings;
  }
}

export type VerticalVideoProps = {
  videoUrl: string;
  thumbnailUrl?: string;
  textTracks?: Array<videojs.TextTrackOptions>;
  title?: string;
  presenterName?: string | null;
  description?: string;
  watched?: boolean;
  options?: VideoJsPlayerOptions;
  onReady?: (player: VideoJsPlayer) => void;
  onClose?: (percent: number) => void;
  showCloseButton?: boolean;
  onEnded?: () => unknown;
  onSubtitleChange?: (track?: TextTrack) => void;
  isReady?: boolean;
  orientation?: 'landscape' | 'portrait';
  aspectRatio?: string;
  objectFit?: BoxProps['objectFit'];
  objectPosition?: BoxProps['objectPosition'];
  withSpeedControl?: boolean;
  sx?: SystemStyleObject;
  isFullScreen?: boolean;
  autoPlay?: boolean;
  onLoadedMetadata?: React.ReactEventHandler<HTMLMediaElement>;
  onTimeUpdate?: React.ReactEventHandler<HTMLMediaElement>;
  subtitlesLanguage?: string | boolean | null;
  muted?: boolean;
  onMutedChange?: (mutedPref: boolean) => void;
  boxProps?: BoxProps;
  children?: React.ReactNode | ((player: VideoJsPlayer) => React.ReactNode);
  muxData?: MuxVideoData;
};

const defaultOptions: VideoJsPlayerOptions = {
  preload: 'auto',
  controls: true,
  fill: true,
  responsive: true,
  fluid: false,
  controlBar: {
    pictureInPictureToggle: false,
    fullscreenToggle: false,
    progressControl: {
      seekBar: false,
    },
  },
};

export const VerticalVideo = forwardRef<MediaHandleRef, VerticalVideoProps>(
  function VerticalVideo(
    {
      videoUrl,
      thumbnailUrl,
      textTracks,
      options,
      isReady = true,
      objectFit = 'cover',
      objectPosition,
      autoPlay = true,
      boxProps,
      muxData,
      ...props
    },
    ref,
  ) {
    const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null);
    const [player, setPlayer] = useState<VideoJsPlayer>();
    const videoRef = useRef<HTMLVideoElement | null>(null);
    videoRef.current = videoEl;

    const playerRef = useRef<VideoJsPlayer | null>(null);

    const handleReady = useCallbackRef<Required<VerticalVideoProps>['onReady']>(
      (player) => props.onReady?.(player),
    );

    const setupVideo = useCallbackRef((videoEl: HTMLVideoElement) => {
      setVideoEl(videoEl);

      if (!videoEl) {
        return;
      }

      const player = (playerRef.current = videojs(
        videoEl,
        {
          ...defaultOptions,
          ...options,
          sources: videoUrl ? [{ src: videoUrl }] : [],
          tracks: textTracks ?? [],
          html5: {
            /*
              Enable native text tracks on iOS & Desktop Safari only.
              Chrome does not support native text tracks with HLS because it doesn't
              support HLS natively.
              iOS Safari does not support emulated text tracks included in .m3u8 HLS streams.

              We could emulate across the board, but would need to explicitly specify
              the text tracks instead of relying on the HLS manifest.

              Here we check for `Mobile/` in the user agent as it strongly suggests 
              Chrome device emulation.

              We keep native text tracks for desktop Safari, just to make it
              easier to test.
            */
            nativeTextTracks:
              Capacitor.getPlatform() === 'ios' ||
              (videojs.browser.IS_SAFARI &&
                !navigator.userAgent.includes('Mobile/')),
          },
          plugins: {
            mux: {
              debug: false,
              data: process.env.NEXT_PUBLIC_MUX_DATA_ENV_KEY
                ? {
                    env_key: process.env.NEXT_PUBLIC_MUX_DATA_ENV_KEY,
                    player_name: `Strive/${Capacitor.getPlatform()}`,
                    player_version: process.env.VERSION,
                    ...muxData,
                  }
                : {},
            },
          },
        },
        () => {
          setPlayer(player);
          handleReady(player);
        },
      ));

      player.mobileUi({
        forceForTesting: false,
        fullscreen: {
          enterOnRotate: false,
          exitOnRotate: false,
          iOS: false,
        },
      });

      player.src(videoUrl);
      if (thumbnailUrl) {
        player.poster(thumbnailUrl);
      }
    });

    useEffect(() => {
      // Dispose the Video.js player when the functional component unmounts
      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      };
    }, []);

    //sync mute
    const handleMutePrefChange = useCallbackRef(props.onMutedChange);

    useEffect(() => {
      let muted = videoEl?.muted;
      videoEl?.addEventListener('volumechange', (event) => {
        let videoElTarget = event.target as HTMLVideoElement;
        if (videoElTarget.muted !== muted) {
          muted = videoElTarget.muted;
          handleMutePrefChange(muted);
        }
      });
    }, [videoEl, handleMutePrefChange]);

    // Syncronize the subtitles
    const handleSubtitlesChange = useCallbackRef(props.onSubtitleChange);
    useEffect(() => {
      if (player) {
        const setSubtitles = () => {
          for (const track of Array.from(player.textTracks())) {
            if (props.subtitlesLanguage === track.language) {
              track.mode = 'showing';
            } else {
              track.mode = 'hidden';
            }
          }
        };

        // Set the subtitles language, text tracks may not be available yet
        setSubtitles();
        player
          .textTracks()
          .addEventListener('addtrack', () => setTimeout(setSubtitles));

        // Add subtitles change handler
        const handler = () => {
          const tracks = Array.from(player.textTracks());
          const activeTrack = tracks.find((track) => track.mode === 'showing');
          handleSubtitlesChange(activeTrack);
        };
        player.textTracks().addEventListener('change', handler);

        return () => {
          player.textTracks().removeEventListener('change', handler);
        };
      }
    }, [player, handleSubtitlesChange, props.subtitlesLanguage]);

    // Install media player hooks
    const [mediaState, send] = useMediaPlaybackMachine(videoRef, {
      mediaType: 'video',
    });
    const getState = useCallbackRef(() => mediaState);

    useImperativeHandle(ref, () => ({
      player: playerRef.current,
      mediaEl: videoEl,
      getState,
      getContext: () => getState().context,
    }));

    const descriptionModal = useDisclosure();
    const isSuspended = useMediaIsSuspended();
    useSuspendableMedia(videoEl);
    return (
      <Box
        className={clsx('video-frame', {
          'video-frame--fullscreen': props.isFullScreen,
        })}
        {...boxProps}
      >
        <Box data-vjs-player>
          <chakra.video
            sx={{
              ...props.sx,
              video: {
                aspectRatio: props.aspectRatio?.replace(':', '/'),
                objectFit,
                objectPosition,
                height: '100%',
              },
              '.vjs-poster': {
                backgroundSize: objectFit,
                backgroundPosition: objectPosition,
              },
            }}
            playsInline
            className="video-js vjs-fill vjs-big-play-centered vjs-theme-fantasy"
            maxHeight="100%"
            height="100%"
            width="100%"
            hidden={!isReady}
            ref={setupVideo}
            poster={thumbnailUrl}
            autoPlay={autoPlay && !isSuspended}
            onEnded={props.onEnded}
            onLoadedMetadata={props.onLoadedMetadata}
            onTimeUpdate={props.onTimeUpdate}
            crossOrigin="anonymous"
            muted={props.muted}
          />
        </Box>

        <VideoPlayerComponent player={player} className="video-frame-header">
          <Flex gap="4" p="3" pl={4} alignItems={'center'}>
            {props.description ? (
              <>
                <Modal
                  {...descriptionModal}
                  variant="video-modal"
                  scrollBehavior="inside"
                  isCentered={!props.isFullScreen}
                >
                  <ModalOverlay backdropFilter="none" bg="none" />
                  <ModalContent
                    bgColor="modal.bg"
                    maxHeight="33%"
                    mt={`calc(50px + ${
                      props.isFullScreen ? 'env(safe-area-inset-top)' : 0
                    })`}
                  >
                    <ModalBody py="3" px="5">
                      {props.description}
                    </ModalBody>
                  </ModalContent>
                </Modal>
                <IconButton
                  variant="unstyled"
                  aria-label="Description"
                  textAlign={'left'}
                  onClick={descriptionModal.onToggle}
                >
                  <Icon
                    as={InfoIcon}
                    size="4"
                    color="gray.200"
                    filter="drop-shadow(var(--icon-shadow))"
                  />
                </IconButton>
              </>
            ) : null}

            {props.showCloseButton && props.onClose ? (
              <IconButton
                aria-label="Close"
                variant="unstyled"
                ml="auto"
                onClick={() => {
                  props.onClose?.(mediaState.context.currentPercent);
                }}
              >
                <Icon
                  as={CloseIcon}
                  size="5"
                  color="gray.200"
                  filter="drop-shadow(var(--icon-shadow))"
                />
              </IconButton>
            ) : null}
          </Flex>
        </VideoPlayerComponent>

        <VideoPlayerComponent
          player={player}
          overlayActive
          overlayPaused
          className="video-frame-footer"
        >
          <Flex gap="4" align="end" pointerEvents="none">
            {props.title && (
              <Flex direction="column" align="start" gap="2" width="100%" p="4">
                {props.watched ? (
                  <Badge colorScheme="whiteAlpha">Watched</Badge>
                ) : null}

                <Text fontWeight="bold" lineHeight="1.3" mt={8}>
                  {props.title}
                </Text>

                {props.presenterName ? (
                  <Text fontWeight="bold" fontSize="sm" lineHeight="1.3">
                    {props.presenterName}
                  </Text>
                ) : null}
              </Flex>
            )}

            {props.withSpeedControl && (
              <PlaybackRateButtons
                className="vjs-strive vjs-strive-overlay-active"
                direction="column"
                currentRate={mediaState.context.playbackRate}
                onChange={(rate) => send({ type: 'changePlaybackRate', rate })}
                pointerEvents="auto"
                right="0"
                p="4"
                fontWeight="bold"
                transform="translateX(100%)"
              />
            )}
          </Flex>
        </VideoPlayerComponent>

        {videoEl?.muted && (
          <VideoPlayerComponent
            player={player}
            className="video-control-replace"
            overlayInactive
          >
            <Flex w="full" direction={'row'} mb={'3'}>
              <Box ml="auto">
                <button
                  style={{ marginLeft: 'auto', marginRight: 3 }}
                  onClick={() => {
                    if (player) {
                      player.muted(false);
                    }
                    if (videoEl) {
                      videoEl.volume = 1;
                    }
                  }}
                  className="vjs-mute-control vjs-control vjs-button vjs-vol-0"
                  type="button"
                  title="Unmute"
                  aria-disabled="false"
                >
                  <span
                    className="vjs-icon-placeholder"
                    aria-hidden="true"
                  ></span>
                  <span className="vjs-control-text" aria-live="polite">
                    Unmute
                  </span>
                </button>
              </Box>
            </Flex>
          </VideoPlayerComponent>
        )}

        <VideoPlayerComponent
          player={player}
          parent="controlBar.progressControl"
          styles={{ width: '100%' }}
        >
          {player && (
            <Flex w="full" pl="4">
              <MediaScrubber
                player={player}
                thumbSize="4"
                thumbIconProps={{
                  sx: {
                    circle: {
                      fill: 'currentColor',
                    },
                  },
                }}
              />
            </Flex>
          )}
        </VideoPlayerComponent>

        {player
          ? typeof props.children === 'function'
            ? props.children(player)
            : props.children
          : null}
      </Box>
    );
  },
);
