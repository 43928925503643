import 'videojs-mux';
import 'videojs-mobile-ui';

import { useEffect, useRef, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  Box,
  BoxProps,
  chakra,
  Flex,
  SystemStyleObject,
  useCallbackRef,
} from '@chakra-ui/react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

import { VideoPlayerComponent } from '@arena-labs/strive2-ui';

import { useMediaPlaybackMachine } from '../media/media-playback.machine';
import { MuxVideoData } from '../mux-data';
import { MediaScrubber } from './media-scrubber';
import { PlaybackRateButtons } from './playback-rate-buttons';

export type VideoPlayerProps = {
  videoUrl: string;
  thumbnailUrl?: string;
  options?: VideoJsPlayerOptions;
  onReady?: (player: VideoJsPlayer) => void;
  onPlayerClosed?: (percent: number) => void;
  onLoadedMetadata?: React.ReactEventHandler<HTMLMediaElement>;
  onTimeUpdate?: React.ReactEventHandler<HTMLMediaElement>;
  onEnded?: () => unknown;
  isReady?: boolean;
  orientation?: 'landscape' | 'portrait';
  aspectRatio?: string;
  objectFit?: BoxProps['objectFit'];
  withSpeedControl?: boolean;
  sx?: SystemStyleObject;
  muxData?: MuxVideoData;
};

const defaultOptions: VideoJsPlayerOptions = {
  autoplay: true,
  controls: true,
  fill: true,
  responsive: true,
  fluid: false,
  controlBar: {
    progressControl: {
      seekBar: false,
    },
  },
};

export function VideoPlayer({
  videoUrl,
  thumbnailUrl,
  options,
  onReady,
  onPlayerClosed,
  onEnded,
  onLoadedMetadata,
  onTimeUpdate,
  isReady = true,
  orientation = 'landscape',
  aspectRatio,
  objectFit,
  withSpeedControl,
  sx,
  muxData,
}: VideoPlayerProps) {
  const [player, setPlayer] = useState<VideoJsPlayer>();
  const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null); // TODO: [refactor] Remove this ref
  videoRef.current = videoEl;

  const playerRef = useRef<VideoJsPlayer | null>(null);

  const handleReady = useCallbackRef<Required<VideoPlayerProps>['onReady']>(
    (player) => onReady?.(player),
  );

  const setupVideo = useCallbackRef((videoEl: HTMLVideoElement) => {
    setVideoEl(videoEl);

    if (!videoEl) {
      return;
    }

    const player = (playerRef.current = videojs(
      videoEl,
      {
        ...defaultOptions,
        ...options,
        plugins: {
          mux: {
            debug: false,
            data: process.env.NEXT_PUBLIC_MUX_DATA_ENV_KEY
              ? {
                  env_key: process.env.NEXT_PUBLIC_MUX_DATA_ENV_KEY,
                  player_name: `Strive/${Capacitor.getPlatform()}`,
                  player_version: process.env.VERSION,
                  ...muxData,
                }
              : {},
          },
        },
        sources: videoUrl ? [{ src: videoUrl }] : [],
      },
      () => {
        setPlayer(player);
        handleReady(player);
      },
    ));

    player.mobileUi({
      forceForTesting: false,
      fullscreen: {
        enterOnRotate: orientation === 'landscape',
        exitOnRotate: orientation === 'landscape',
        iOS: true,
      },
    });

    player.src(videoUrl);
    if (thumbnailUrl) {
      player.poster(thumbnailUrl);
    }
  });

  useEffect(() => {
    // Dispose the Video.js player when the functional component unmounts
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  // Install media player hooks
  const [mediaState, send] = useMediaPlaybackMachine(videoRef, {
    mediaType: 'video',
    onPlayerClosed,
  });

  return (
    <div className="video-frame video-frame--auto">
      <Box data-vjs-player>
        <chakra.video
          sx={{
            ...sx,
            video: { aspectRatio: aspectRatio?.replace(':', '/'), objectFit },
          }}
          width="full"
          height="auto"
          playsInline
          className="video-js vjs-fill vjs-big-play-centered vjs-theme-fantasy"
          maxHeight="100%"
          hidden={!isReady}
          ref={setupVideo}
          poster={thumbnailUrl}
          onEnded={onEnded}
          onTimeUpdate={onTimeUpdate}
          onLoadedMetadata={onLoadedMetadata}
        />
      </Box>
      <VideoPlayerComponent player={player}>
        <Box className="video-frame-footer" left="auto">
          {withSpeedControl && (
            <PlaybackRateButtons
              className="vjs-strive vjs-strive-overlay-active"
              direction="column"
              currentRate={mediaState.context.playbackRate}
              onChange={(rate) => send({ type: 'changePlaybackRate', rate })}
              pointerEvents="auto"
              right="0"
              p="4"
              fontWeight="bold"
              transform="translateX(100%)"
            />
          )}
        </Box>
      </VideoPlayerComponent>

      <VideoPlayerComponent
        player={player}
        parent="controlBar.progressControl"
        styles={{ width: '100%' }}
      >
        {player && (
          <Flex w="full" pl="4">
            <MediaScrubber
              player={player}
              thumbSize="4"
              thumbIconProps={{
                sx: {
                  circle: {
                    fill: 'currentColor',
                  },
                },
              }}
            />
          </Flex>
        )}
      </VideoPlayerComponent>
    </div>
  );
}
